:root {
  --doc-height: 100%;
  --space: 16px;
}

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;800&display=swap');

html,
body{
  padding: 0;
  margin: 0;
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  /*background-color: #333;*/
  font-size: 16px;
  line-height: 1.5;
  color: rgba(0,0,0,.87);
}

html, body, #root{
  height: 100%;
  height: var(--doc-height);
}

h1, h2, h3, h4, h5, p{
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: subpixel-antialiased;
  color: rgba(0,0,0,.87);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


.outer{
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.outerPadding{
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--space);
}

.chunk{
  padding-bottom: calc(var(--space) * 1.35);
}

.bounds{
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}

.title{
  font-size: 36px;
  line-height: 1.05;
  letter-spacing: -0.25px;
  padding: 4px 0;
}

.titleLong{
  font-size: 26px;
}

.titleSuperLong{
  font-size: 24px;
}


.pageTitle{
  font-size: 24px;
  font-weight: 800;
  line-height: 1.3;
}

.secondaryText{
  color: rgba(0,0,0,.5);
}

.smallText{
  font-size: 14px;
}


.button{
  text-align:  center ;
  display:  inline-block;
  min-width: 0;
  text-align:  center ;
  color:  white ;
  border-radius: 32px;
  padding:  14px 16px ;
  cursor:  pointer ;
  font-weight: 600;
  background-color:	#2589FD;
  white-space: nowrap;
  font-size: 15px;
}

.buttonSmall{
  padding:  8px 14px;
  font-Size: 14px;
}

.header, .footer, .main{
}

.header{
  padding-bottom: 10px;
}

.main{
  /* border-bottom: 4px solid #333; */
  border-top: 6px solid #333;
  padding-top: calc(var(--space) * 1);
  /* margin-bottom: calc(var(--space) * 1); */
}

.section{
  padding-bottom: 0;
}

.logoText{
  font-family: Palatino, 'Times New Roman', Times, serif;
  font-variant: small-caps;
  font-size: 18px;
  font-weight: 600;
  color: #111;
  line-height: 1.3;
  letter-spacing: .1px;
}

.score{
  white-space: nowrap;
  font-weight: 700;
  font-size: 14px;
  border-radius: 6px;
  /*padding: 8px;*/
  margin-top: -4px;
  /* background: #f7f7f7; */
}

/*
.goodScore{
  background: gold;
}

.badScore{
  background: #f7f7f7;
}
*/

.headerText{
  font-size: 13px;
  line-height: 1.3;
  white-space: nowrap;
}

.headerIcon{
  background: black;
  border-radius: 28px;
  height: 28px;
  width: 28px;
  margin-right: 7px;
  box-shadow: 0 0 0 .5px rgba(0,0,0,.75);
  box-sizing: content-box;
}

.footerText{
  font-size: 13px;
  line-height: 1.3;
}

.link{
  text-decoration: underline;
  cursor: pointer;
}

.clickable{
  cursor: pointer;
}

@media (min-width: 400px) {
  .title, .titleLong{
    font-size: 36px;
  }
  .titleSuperLong{
    font-size: 30px;
  }

}

@media ((min-width: 400px) and (min-height: 800px)) or (min-width: 640px) {

  html,
  body{
    font-size: 18px;
    line-height: 1.4;
  }

  .smallText{
    font-size: 16px;
  }

  .pageTitle{
    font-size: 24px;
  }

}

@media (min-width: 640px){

  .outerPadding{
    padding: calc(var(--space) * 1.5);
  }

  .title, .titleLong, .titleSuperLong{
    font-size: 48px;
    line-height: 1.1em;
  }

  .button{
    font-size: 18px;
    padding: 16px 20px;
    line-height: 1;
  }

  .buttonSmall{
    padding:  12px 16px;
    font-Size: 14px;
    min-width: 120px;
  }

  .logoText{
    font-size: 20px;
    line-height: 1.2;
  }

  .headerText{
    font-size: 15px;
    line-height: 1.2;
  }


  .headerIcon{
    border-radius: 32px;
    height: 28px;
    width: 28px;
    margin-right: 6px;
  }

  .footerText{
    font-size: 15px;
    line-height: 1.2;
  }
}